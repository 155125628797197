import {request} from '@/utils/request'

// 店铺
export function getShopStore(id) {
  return request('GET','/shopWeb/store/' + id, {}, true)
}

export function getShopStoreCollect(id) {
  return request('GET','/shop/user/store/collect/' + id, {}, true)
}

export function postShopStoreCollect(id) {
  return request('POST','/shop/user/store/collect/' + id, {}, true)
}

export function getShopStoreGoods(data) {
  return request('GET','/shop/goods/store/category', data, true)
}

export function getShopStoreGoodsSearch(data) {
  return request('GET','/shop/goods/store/list', data, true)
}

export function getShopStoreCollectList(data) {
  return request('GET','/shop/user/store/collect/list', data, true)
}

